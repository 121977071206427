import React, { FunctionComponent } from 'react';
import '../styles/Logo.scss';

interface LogoProps {

}

const Logo: FunctionComponent<LogoProps> = (props) => {
    return (
        <div className="logo-container">
            <div className="logo-bars">
                <div className="logo-bar gray" />
                <div className="logo-bar blue" />
                <div className="logo-bar red" />
                <div className="logo-bar orange" />
            </div>
            <div className="logo-text">
                <div className="logo-name">Haley</div>
                <div className="logo-divider">&</div>
                <div className="logo-name">Evan</div>
            </div>
            <div className="logo-bars">
                <div className="logo-bar gray" />
                <div className="logo-bar blue" />
                <div className="logo-bar red" />
                <div className="logo-bar orange" />
            </div>
        </div>
    )
}

export { Logo }
