import React, { FunctionComponent, useEffect, useState } from 'react';
import './App.scss';
import { AuthManager } from './components/AuthManager';
import { PhotoViewer } from './components/PhotoViewer';
import * as AWS from 'aws-sdk';
import { WeddingInfo } from './components/WeddingInfo';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { HashRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ContentPage } from './components/ContentPage';
import { Registration } from './components/Registration';
import { SignIn } from './components/SignIn';
import { ForgotPassword } from './components/ForgotPassword';
import { PasswordReset } from './components/PasswordReset';
import { Rsvp } from './components/Rsvp';
import { userInfo } from 'os';
import { Registry } from './components/Registry';
import { Accommodations } from './components/Accommodations';
import { ThingsToDo } from './components/ThingsToDo';
import { WeddingParty } from './components/WeddingParty';
import { ThankYou } from './components/ThankYou';

const App: FunctionComponent = () => {

  const [user, setUser] = useState<CognitoUser | null>();

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((currentUser: CognitoUser) => {
      setUser(currentUser);
    });
    Auth.currentCredentials().then(res => {
      AWS.config.credentials = res;
    });
  }, [])

  return (
    <div className="App">
      {user ?
        // Authed
        <Router>
          <Routes>
            {/* <Route path="" element={<Navigate to="/info" />} />
            <Route path="/" element={<ContentPage />}>
            <Route path="/info" element={<WeddingInfo />} />
              <Route path="/engagement" element={<PhotoViewer />} />
              <Route path="/rsvp" element={<Rsvp user={user}/>} />
              <Route path="/registry" element={<Registry />} />
              <Route path="/accommodations" element={<Accommodations />} />
              <Route path="/things-to-do" element={<ThingsToDo />} />
              <Route path="/wedding-party" element={<WeddingParty />} />
            </Route>
            <Route path="*" element={<Navigate to="" />} /> */}
            <Route path="*" element={<ThankYou/>} />
          </Routes>
        </Router>
        // Not Authed
        : <Router>
          <Routes>
            {/* <Route path="" element={<Navigate to="/register" />} /> */}
            {/* <Route path="/" element={<AuthManager />}>
              <Route path="/register" element={<Registration setUser={setUser} /> } />
              <Route path="/sign-in" element={<SignIn setUser={setUser}/> } />
              <Route path="/forgot-password" element={<ForgotPassword /> } />
              <Route path="/password-reset" element={<PasswordReset /> } />
            </Route> */}
            <Route path="*" element={<ThankYou/>} />
          </Routes>
        </Router>
      }
    </div>
  );
}

export default App;
