import React, { FunctionComponent } from 'react';
import { Logo } from './Logo';


const ThankYou: FunctionComponent = () => {

    return (
        <div className="page-container">
            <Logo />
            <div className="info-container">
                <h1 className="wedding-date">Thank You</h1>
                <p>
                    Thank you for joining us!
                </p>
            </div>
        </div>
    )
}

export { ThankYou }
