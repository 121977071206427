import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Amplify from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.min.css';
import AWS from 'aws-sdk';


Amplify.configure({
  Auth: {
    region: 'us-east-1',
    identityPoolId: 'us-east-1:8dace0a9-e855-4fa1-99ba-ad80709bb4db',
    userPoolId: 'us-east-1_J8Tz8V0pb',
    userPoolWebClientId: '3n6hnufjg9ul4895gigibkvrut',
    mandatorySignIn: true
  },
  Storage: {
    AWSS3: {
      bucket: 'haley-evan-2022-wedding-images',
      region: 'us-east-1'
    }
  }
});

AWS.config.update({
  region: "us-east-1"
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
